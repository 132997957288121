import React from "react"
import Layout from "../../../components/layout"
import { graphql } from 'gatsby'
import { Text, Title } from "gatsby-theme-material-foundry"
import { Box, Container, makeStyles, Grid, Card, CardContent, CardActionArea } from "@material-ui/core"
import Check from "@material-ui/icons/Check"
import BackgroundImage from "gatsby-background-image"
import SEO from "../../../components/SEO/seo"
import Img from "gatsby-image"
import {
  StudentFormModal,
  EmployeeFormModal,
} from "../../../components/forms/makerspace"

const ListItem = ({ text }) => (
  <Box display="flex" my={1} mx={4}>
    <Check />
    <Box ml={1}>
      <Text black>{text}</Text>
    </Box>
  </Box>
)

const useStyles = makeStyles(theme => ({
  hero: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  heroTitle: {
    color: "white",
    textShadow: "2px 2px 4px #000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
    },
  },

}))

function MakerspacePage(props) {
  const classes = useStyles()

  return (
    <Layout dark>
      <SEO
        postTitle={`Marineholmen Makerspace`}
        slug={`campus/bergen/makerspace`}
      />
      <BackgroundImage
        alt="makerspace"
        fluid={props.data.cover.childImageSharp.fluid}
      >
        <Box align="center" py={16}>
          <Container maxWidth="md">
            <Box pt={12}>
              <Title variant="h1" gutterBottom className={classes.heroTitle}>
                Marineholmen Makerspace
              </Title>
            </Box>
          </Container>
        </Box>
      </BackgroundImage>
     
        <Container maxWidth="md">
        <Box my={8}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Title variant="h4" black>
                Hva er Marineholmen Makerspace?
              </Title>
              <Text black paragraph>
                Marineholmen Makerspace er et fullverdig verksted hvor du får du
                tilgang til verktøyet, maskinene, kursene og kompetansen du
                trenger for å skape noe selv. Her får du ta del i et kreativt
                miljø hvor du kan skape, utveksle erfaringer og bygge nettverk
                med andre skapere.
              </Text>
              <Text black paragraph>
                Gjennom HVL skape sitt makerspacetilbud får du tilgang til 420
                kvm med alt du trenger – inkludert blant annet 3D-printere,
                laserkuttere, CNC-maskin, symaskiner og loddebolt. Vi arrangerer
                også en rekke kurs, blant annet i bruk av utstyret i Makerspace.
              </Text>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Box mt={6}>
                <Img
                  fluid={props.data.printer.childImageSharp.fluid}
                  alt="3dprinter"
                />
              </Box>
            </Grid>
          </Grid>
          </Box>
        </Container>
        <div
          style={{
            background: "rgba(138, 244, 255, 0.5)",
            color: "#004357",
          }}
        >
          <Box pt={4} pb={12}>
            <Container maxWidth="md">
              <Box p={4}>
              <Title variant="h4" black align="center">
                Tilbud for HVL studenter og ansatte
              </Title>
              <Text black variant="h6" align="center">
                <strong>
                  Makerspace kan brukes til individuelle medlemskap for HVLs
                  ansatte og studenter. Det kan også brukes til faglige
                  arrangement i og utenfor undervisning (som hackaton,
                  bootcmaps, workshops, prototypeseminar, foredrag mv.). For å
                  få HVLs medlemspris eller bruke Makerspace kostnadsfritt, må
                  du registerere deg her
                </strong>
              </Text>
              </Box>
            </Container>
            <Container maxWidth="md">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Card>
                
                      <Title variant="h5" black align="center">
                        Søk om medlemskap
                      </Title>
                      <CardContent style={{minHeight: "200px"}}>
                      <ListItem text="alle studenter og ansatte ved HVL kan bli medlemmer" />
                      <ListItem text="Få nøkkelkort for fri adgang" />
                      <ListItem text="Mulighet for opplæring av 3d printer, CNC etc." />
                      </CardContent>
                      <CardActionArea>
                      <Box align="center" my={2}>
                        <StudentFormModal />
                      </Box>
                      </CardActionArea>
           
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
               
                      <Title variant="h5" black align="center">
                        Søk om bruk
                      </Title>
                      <CardContent style={{minHeight: "200px"}}>
                      <ListItem text="Bruk makerspace i undervisning" />
                      <ListItem text="sett opp et arrangement" />
                      <ListItem text="Mulighet for opplæring av 3d printer, CNC etc." />
                      </CardContent>
                      <CardActionArea>
      
                      <Box align="center" my={2}>
                        <EmployeeFormModal />
                      </Box>
                 
                      </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>

    </Layout>
  )
}
export const ItemPageQuery = graphql`
  query Makerspace {
    cover: file(name: { eq: "makerspace" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    printer: file(name: { eq: "3dprinter" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default MakerspacePage
