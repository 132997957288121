import React from "react"
import {
  Divider,
  TextField,
  Box,
  Grid,
  LinearProgress,
} from "@material-ui/core"
import { Title,Text, Button as FoundryButton } from "gatsby-theme-material-foundry"
import { makeStyles } from "@material-ui/core/styles"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import Personvern from "../../personvernCheckbox"
import { submit } from "../submit"
import { SelectFaculty, Radiobuttons } from "../inputs"
import Modal from "../../modal"

const validationSchema = Yup.object().shape({
  mittFakultet: Yup.string().required("Du må velge et fakultet"),
  mittNavn: Yup.string().required("Du må skrive et navn"),
  antallDeltakere: Yup.number().required("Du må skrive et navn"),
  emnekode: Yup.string().required("Du må skrive et navn"),
  checked: Yup.boolean().oneOf([true], "Du må godkjenne personværnerklæringen"),
  minEmail: Yup.string()
    .required("Du må skrive en epost")
    .email("må være en epost"),
})

const useStyles = makeStyles(theme => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.black.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.black.main}`,
      },
    },
  },
  "& input:valid:focus + fieldset": {
    borderColor: theme.palette.black.main,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
}))

const EmployeeForm = ({ handleClose, label }) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        student: true,
        mittNavn: "",
        mittFakultet: "",
        emnekode: "",
        minTelefon: "",
        minEmail: "",
        kurs: "",
        antallDeltakere: "",
        checked: false,
        beskrivelse: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) =>
      submit({
        resetForm : resetForm, setStatus : setStatus, setSubmitting: setSubmitting, handleClose: handleClose,
      email: {
        content: [
          { text: "Typ av arrangement", value: values.kurs },
              { text: "Navn", value: values.mittNavn },
              { text: "Email", value: values.minEmail },
              { text: "Telefon", value: values.minTelefon },
              { text: "Fakultet", value: values.mittFakultet },
              { text: "Emnekode", value: values.emnekode },
              { text: "AntallDeltakere", value: values.antallDeltakere },
              { text: "Beskrivelse", value: values.beskrivelse },
        ],
        toEmail: values.minEmail,
        name: values.mittNavn,
        fromEmail: "skape@hvl.no",
        ccEmail: ["skape@hvl.no", "post@marineholmenmakerspace.freshdesk.com"],
        title: "Takk for din søknad!",
        type: "",
        description: `Takk for din søknad om å bruke marineholmen makerspace som ansatt. Du blir kontaktet direkte av Marineholmen Makerspace for å bedre kartlegge behov.`,
        subject: `Ny søknad om bruk av makerspace for ${values.kurs}`,
      }})}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        status,
        isSubmitting,
      }) => (
        <Form className={classes.root}>
          <Divider style={{ marginBottom: 20 }} />
          <Radiobuttons
            label="Arrangement eller Kurs"
            name="kurs"
            value={values.kurs}
            handleBlur={handleBlur}
            handleChange={handleChange}
            items={[
              { label: "Engangsarrangement", value: "arrangement" },
              { label: "For bruk i undervisning", value: "Undervisning" },
              { label: "Annet", value: "Annet" },
            ]}
          />
          <TextField
            name="mittNavn"
            helperText={touched.mittNavn ? errors.mittNavn : ""}
            error={Boolean(errors.mittNavn)}
            label="Mitt navn"
            margin="dense"
            required
            variant="outlined"
            value={values.mittNavn}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="minEmail"
            type="email"
            margin="dense"
            required
            helperText={touched.minEmail ? errors.minEmail : ""}
            error={Boolean(errors.minEmail)}
            label="Min email"
            variant="outlined"
            value={values.minEmail}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="minTelefon"
            type="tel"
            margin="dense"
            required
            helperText={touched.minTelefon ? errors.minTelefon : ""}
            error={Boolean(errors.minTelefon)}
            label="Mitt telefonnummer"
            variant="outlined"
            value={values.minTelefon}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <SelectFaculty
            name="mittFakultet"
            error={errors.mittFakultet}
            touched={touched.mittFakultet}
            label="Mitt HVL-fakultet"
            value={values.mittFakultet}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                name="antallDeltakere"
                helperText={
                  touched.antallDeltakere ? errors.antallDeltakere : ""
                }
                error={Boolean(errors.mittStudentnummer)}
                label="Anslått antall deltakere"
                margin="dense"
                variant="outlined"
                placeholder="50"
                type="number"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.antallDeltakere}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box ml={1}>
                <TextField
                  name="emnekode"
                  helperText={
                    touched.emnekode
                      ? errors.emnekode
                      : "Skriv Emnekoden XXX123"
                  }
                  error={Boolean(errors.emnekode)}
                  label="Emnekode"
                  margin="dense"
                  variant="outlined"
                  type="text"
                  value={values.emnekode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
          <TextField
            margin="dense"
            label="Beskrivelse"
            required
            name="beskrivelse"
            helperText={touched.beskrivelse ? errors.beskrivelse : ""}
            error={Boolean(errors.beskrivelse)}
            type="text"
            onChange={handleChange}
            value={values.beskrivelse}
            fullWidth
            variant="outlined"
            multiline
            onBlur={handleBlur}
            rows="4"
          />
          <Personvern
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {isSubmitting && <LinearProgress />}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              alignContent="center"
              justifyContent="center"
              display="flex"
            >
              {status && status === "Melding sendt, takk!" ? (
                <Title sendondary>{status}</Title>
              ) : (
                <Title error>{status}</Title>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="right">
                <FoundryButton
                  className={classes.button}
                  type="submit"
                  disabled={Boolean(!isValid)}
                  color="primary"
                  variant="contained"
                >
                  {label}
                </FoundryButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export function EmployeeFormModal() {
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Modal
    open={open}
    buttonLabel="Søk om bruk"
      handleOpen={handleOpen}
      handleClose={handleClose}
      modalTitle="Søk om bruk av Makerspace"
      modalDescription={
        <Text info>
          Makerspace kan brukes i undervisning eller for arrangement, les mer her:{" "}
          <a href="https://marineholmenmakerspace.no">
            marineholmenmakerspace.no
          </a>. <br/><strong>NB! En person på Makerspace tar kontakt med deg direkte etter innsendt skjema for å sikertstille så alt er ok.</strong>
        </Text>
      }
    >
      <EmployeeForm handleClose={handleClose} label="Søk nå" />
    </Modal>
  )
}

export default EmployeeForm
