import React from "react"
import {
  makeStyles, 
  Button,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { FieldArray, getIn } from "formik"
import { Title, Button as FoundryButton } from "gatsby-theme-material-foundry"
import Icon from "@material-ui/icons/Cancel"

const fakultet = [
  {
    value: "FIN",
    label: "FIN - Fakultet for ingeniør- og naturvitskap",
  },
  {
    value: "FHS",
    label: "FHS - Fakultet for helse- og sosialvitskap",
  },
  {
    value: "FLKI",
    label: "FLKI - Fakultet for lærarutdanning, kultur og idrett",
  },
  {
    value: "FØS",
    label: "FØS - Fakultet for økonomi og samfunnsvitskap",
  },
]

const campus = [
  {
    value: "BGN",
    label: "Bergen",
  },
  {
    value: "SGN",
    label: "Sogndal",
  },
  {
    value: "SRD",
    label: "Stord",
  },
  {
    value: "HSD",
    label: "Haugesund",
  },
  {
    value: "FDE",
    label: "Førde",
  },
]

const useStyles = makeStyles(theme => ({
  textField: {
    "& label.Mui-focused": {
      color: theme.palette.black.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.black.main}`,
      },
    },
    "& input:valid:focus + fieldset": {
      borderColor: theme.palette.black.main,
    },
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
}))

export function SelectCampus({
  label,
  value,
  handleChange,
  handleBlur,
  error,
  touched,
  name,
}) {
  const classes = useStyles()

  return (
    <TextField
      select
      required
      margin="dense"
      label={label}
      className={classes.textField}
      value={value}
      name={name}
      variant="outlined"
      onBlur={handleBlur}
      fullwidth
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      error={Boolean(error)}
      helperText={touched ? error : ""}
    >
      {campus.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export function SelectFaculty({
  label,
  value,
  handleChange,
  handleBlur,
  error,
  touched,
  name,
}) {
  const classes = useStyles()

  return (
    <TextField
      select
      required
      margin="dense"
      label={label}
      className={classes.textField}
      value={value}
      name={name}
      variant="outlined"
      onBlur={handleBlur}
      fullwidth
      onChange={handleChange}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      error={Boolean(error)}
      helperText={touched ? error : ""}
    >
      {fakultet.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export function Radiobuttons({
  items,
  label,
  name,
  value,
  handleChange,
  handleBlur,
}) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        row
      >
        {items.map(item => (
          <FormControlLabel
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}


export function PeopleArray({title, name, handleChange, handleBlur, arrayItems, touched, errors, values}){
const classes = useStyles()
  return(
    <FieldArray name={name}>
    {({ push, remove }) => (
      <div>
        <Title black variant="h6">
          {title}
        </Title>
        {values[name].map((p, index) => {
          return (
            <div key={p.id}>
              {arrayItems.map(item => 
                 <TextField
                 className={classes.field}
                 variant="outlined"
                 label={item.label}
                 margin="dense"
                 name={`${name}[${index}]${[item.name]}`}
                 value={p`${name}[${index}]${[item.name]}`}
                 required
                 helperText={getIn(touched, `${name}[${index}]${[item.name]}`) && getIn(errors, `${name}[${index}]${[item.name]}`) ? getIn(errors, `${name}[${index}]${[item.name]}`) : ""}
                 error={Boolean(getIn(touched, `${name}[${index}]${[item.name]}`) && getIn(errors, `${name}[${index}]${[item.name]}`))}
                 onChange={handleChange}
                 onBlur={handleBlur}
               />
                )}
              <FoundryButton
                className={classes.button}
                margin="normal"
                type="button"
                variant="outlined"
                color="error"
                simple
                justIcon
                onClick={() => remove(index)}
              >
                <Icon />
              </FoundryButton>
            </div>
          )
        })}
        <Button
          className={classes.button}
          type="button"
          variant="outlined"
          onClick={() => {
            push({ id: Math.random(), navn: "", email: "" })
          }

          }
        >
          Ny Person
        </Button>
      </div>
    )}
  </FieldArray>
  )
}