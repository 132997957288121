import React from "react"
import {
  Divider,
  TextField,
  Box,
  Grid,
  LinearProgress,
} from "@material-ui/core"
import {
  Title,
  Text,
  Button as FoundryButton,
} from "gatsby-theme-material-foundry"
import Modal from "../../modal"
import { makeStyles } from "@material-ui/core/styles"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import Personvern from "../../personvernCheckbox"
import { submit } from "../submit"
import { SelectFaculty, Radiobuttons } from "../inputs"

const validationSchema = Yup.object().shape({
  mittFakultet: Yup.string().required("Du må velge et fakultet"),
  mittNavn: Yup.string().required("Du må skrive et navn"),
  checked: Yup.boolean().oneOf([true], "Du må godkjenne personværnerklæringen"),
  minEmail: Yup.string()
    .required("Du må skrive en epost")
    .email("må være en epost"),
})

const useStyles = makeStyles(theme => ({
  root: {
    "& label.Mui-focused": {
      color: theme.palette.black.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.black.main}`,
      },
    },
  },
  "& input:valid:focus + fieldset": {
    borderColor: theme.palette.black.main,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  field: {
    margin: theme.spacing(1),
  },
}))

export const StudentForm = ({ handleClose, label }) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        student: "",
        mittNavn: "",
        mittFakultet: "",
        minEmail: "",
        mittStudentnummer: "",
        checked: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) =>
        submit({
            resetForm : resetForm, setStatus : setStatus, setSubmitting: setSubmitting, handleClose: handleClose,
          email: {
            content: [
              { text: "Type", value: values.student },
              { text: "Navn", value: values.mittNavn },
              { text: "Email", value: values.minEmail },
              { text: "Telefon", value: values.minTelefon },
              { text: "Fakultet", value: values.mittFakultet },
              { text: "Studentnummer", value: values.mittStudentnummer },
            ],
            toEmail: values.minEmail,
            name: values.mittNavn,
            fromEmail: "skape@hvl.no",
            ccEmail: ["skape@hvl.no", "Svein.Gunnar.Sjotun@hvl.no"],
            title: "Takk for din søknad!",
            type: "makerspace",
            description: `Takk for din søknad som ${values.student}medlem i Marineholmen Makerspace. For å få adgang til makerspace så må du betale 250kr/mnd i egenandel på marineholmen makerspace sin hjemmeside. Du får da en nøkkelbrikke som gir deg fri adgang og mulighet å melde deg opp på kurs.`,
            subject: `Ny søknad om medlemskap i makerspace for ${values.student} ved HVL`,
          },
      })}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        isValid,
        status,
        isSubmitting,
      }) => (
        <Form noValidate autoComplete="off" className={classes.root}>
          <Divider style={{ marginBottom: 20 }} />
          <Radiobuttons
            label="Er du student eller ansatt?"
            name="student"
            value={values.kurs}
            handleBlur={handleBlur}
            handleChange={handleChange}
            items={[
              { label: "Student", value: "student" },
              { label: "Ansatt", value: "ansatt" },
            ]}
          />
          <TextField
            name="mittNavn"
            helperText={touched.mittNavn ? errors.mittNavn : ""}
            error={Boolean(errors.mittNavn)}
            label="Mitt navn"
            margin="dense"
            required
            variant="outlined"
            value={values.mittNavn}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            name="minEmail"
            type="email"
            margin="dense"
            required
            helperText={touched.minEmail ? errors.minEmail : ""}
            error={Boolean(errors.minEmail)}
            label="Min email"
            variant="outlined"
            value={values.minEmail}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />

          <Grid container>
            <Grid item xs={12} sm={6}>
              <SelectFaculty
                name="mittFakultet"
                error={errors.mittFakultet}
                touched={touched.mittFakultet}
                label="Mitt HVL-fakultet"
                value={values.mittFakultet}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box ml={1}>
                {values.student === "student" && <TextField
                  name="mittStudentnummer"
                  helperText={
                    touched.mittStudentnummer ? errors.mittStudentnummer : ""
                  }
                  error={Boolean(errors.mittStudentnummer)}
                  label="Studentnummer ved HVL"
                  margin="dense"
                  variant="outlined"
                  placeholder="123456"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.mittStudentnummer}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                />}
              </Box>
            </Grid>
          </Grid>

          <Personvern
            errors={errors}
            values={values}
            handleChange={handleChange}
          />
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {isSubmitting && <LinearProgress />}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              alignContent="center"
              justifyContent="center"
              display="flex"
            >
              {status && status === "Melding sendt, takk!" ? (
                <Title sendondary>{status}</Title>
              ) : (
                <Title error>{status}</Title>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box align="right">
                <FoundryButton
                  className={classes.button}
                  type="submit"
                  disabled={Boolean(!isValid)}
                  color="primary"
                  variant="contained"
                >
                  {label}
                </FoundryButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export function StudentFormModal() {
  const [open, setOpen] = React.useState(false)
  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      buttonLabel="Bli medlem"
      handleOpen={handleOpen}
      handleClose={handleClose}
      modalTitle="Bli medlem i Marineholmen makerspace"
      modalDescription={
        <Text info>
          Medlemskapet koster 250kr/mnd for HVL studenter og ansatte. Du kan
          lese mer her:{" "}
          <a href="https://marineholmenmakerspace.no">
            marineholmenmakerspace.no
          </a>. <strong>NB! En bekreftelse blir sendt ut via mail etter registrering med en lenke der du må fylle ut info for å fullføre registreringen å få tilgang til adgangsbrikke.</strong>
        </Text>
      }
    >
      <StudentForm handleClose={handleClose} label="Bli medlem" />
    </Modal>
  )
}

export default StudentForm
